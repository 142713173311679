// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes LandingPage1_slideFromLeft1__ZA1jG {
    0% {
      transform: translateX(-100%);
      opacity: 0;
    }
    100% {
      transform: translateX(0);
      opacity: 1;
    }
  }
  @keyframes LandingPage1_slideFromBottomLeftToCenter__occ4U {
    0% {
      transform: translate(-200%, 100%) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: translate(-60%, 60%) scale(1); 
      opacity: 1;
    }
  }
  @keyframes LandingPage1_slideFromLeft2__Kd2E4 {
    0% {
      transform: translateX(-100%) scale(0.4);
      opacity: 0;
    }
    100% {
      transform: translateX(-40%) scale(1);
      opacity: 1;
    }
  }
  
  .LandingPage1_asteroidAnimation__blzAs {
    animation: LandingPage1_slideFromLeft2__Kd2E4 2s ease-in-out forwards;
  }
  .LandingPage1_rocketAnimation__lP9iR {
    animation: LandingPage1_slideFromBottomLeftToCenter__occ4U 2s ease-in-out forwards;
  }
  @keyframes LandingPage1_fadeInFromBelow__fraPG {
    0% {
      opacity: 0;
      transform: translateY(100%); 
    }
    100% {
      opacity: 1;
      transform: translateY(0); 
    }
  }
  .LandingPage1_textAnimation__orjbn {
    animation: LandingPage1_fadeInFromBelow__fraPG 2s ease-in-out forwards;
    position: absolute;
    bottom: 0%;
    padding-bottom: 3rem;
    padding: 1rem; 
    background: white; 
    width: 100vw;
    height: 22vh;
    font-family: "Lora";
    z-index: 3
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/LandingPage1.module.css"],"names":[],"mappings":"AAAA;IACI;MACE,4BAA4B;MAC5B,UAAU;IACZ;IACA;MACE,wBAAwB;MACxB,UAAU;IACZ;EACF;EACA;IACE;MACE,4CAA4C;MAC5C,UAAU;IACZ;IACA;MACE,wCAAwC;MACxC,UAAU;IACZ;EACF;EACA;IACE;MACE,uCAAuC;MACvC,UAAU;IACZ;IACA;MACE,oCAAoC;MACpC,UAAU;IACZ;EACF;;EAEA;IACE,qEAAiD;EACnD;EACA;IACE,kFAA8D;EAChE;EACA;IACE;MACE,UAAU;MACV,2BAA2B;IAC7B;IACA;MACE,UAAU;MACV,wBAAwB;IAC1B;EACF;EACA;IACE,sEAAkD;IAClD,kBAAkB;IAClB,UAAU;IACV,oBAAoB;IACpB,aAAa;IACb,iBAAiB;IACjB,YAAY;IACZ,YAAY;IACZ,mBAAmB;IACnB;EACF","sourcesContent":["@keyframes slideFromLeft1 {\n    0% {\n      transform: translateX(-100%);\n      opacity: 0;\n    }\n    100% {\n      transform: translateX(0);\n      opacity: 1;\n    }\n  }\n  @keyframes slideFromBottomLeftToCenter {\n    0% {\n      transform: translate(-200%, 100%) scale(0.4);\n      opacity: 0;\n    }\n    100% {\n      transform: translate(-60%, 60%) scale(1); \n      opacity: 1;\n    }\n  }\n  @keyframes slideFromLeft2 {\n    0% {\n      transform: translateX(-100%) scale(0.4);\n      opacity: 0;\n    }\n    100% {\n      transform: translateX(-40%) scale(1);\n      opacity: 1;\n    }\n  }\n  \n  .asteroidAnimation {\n    animation: slideFromLeft2 2s ease-in-out forwards;\n  }\n  .rocketAnimation {\n    animation: slideFromBottomLeftToCenter 2s ease-in-out forwards;\n  }\n  @keyframes fadeInFromBelow {\n    0% {\n      opacity: 0;\n      transform: translateY(100%); \n    }\n    100% {\n      opacity: 1;\n      transform: translateY(0); \n    }\n  }\n  .textAnimation {\n    animation: fadeInFromBelow 2s ease-in-out forwards;\n    position: absolute;\n    bottom: 0%;\n    padding-bottom: 3rem;\n    padding: 1rem; \n    background: white; \n    width: 100vw;\n    height: 22vh;\n    font-family: \"Lora\";\n    z-index: 3\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"asteroidAnimation": `LandingPage1_asteroidAnimation__blzAs`,
	"slideFromLeft2": `LandingPage1_slideFromLeft2__Kd2E4`,
	"rocketAnimation": `LandingPage1_rocketAnimation__lP9iR`,
	"slideFromBottomLeftToCenter": `LandingPage1_slideFromBottomLeftToCenter__occ4U`,
	"textAnimation": `LandingPage1_textAnimation__orjbn`,
	"fadeInFromBelow": `LandingPage1_fadeInFromBelow__fraPG`,
	"slideFromLeft1": `LandingPage1_slideFromLeft1__ZA1jG`
};
export default ___CSS_LOADER_EXPORT___;
